const DICH_VU = "dich_vu"
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",
  HO_TRO: "/ho-tro",
  XAC_NHAN: "/xac-nhan-thong-tin",
  THU_TUC_HANH_CHINH: "/thu-tuc-hanh-chinh",
  HUONG_DAN_THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN:
    "/thu-tuc-hanh-chinh/thu-tuc-cap-lai-cap-pho-van-bang-bao-ho-shcn",
  DANG_NHAP: "/dang-nhap",
  DANG_KY: "/dang-ky",
  DOI_MAT_KHAU: "/doi-mat-khau",
  TRA_CUU_KET_QUA: "/tra-cuu-ket-qua",
  TEST_PRINT: "/test-print",
  PAYMENT_SUCCESS: "/payment-success",
  
  //ANONYMOUS
  PREVIEW_PDF: "/preview-pdf",
  QUEN_MAT_KHAU: "/quen-mat-khau",
  // ADMIN
  DANH_BA_TO_CHUC_DAI_DIEN: "/danh-ba-to-chuc-dai-dien",
  DANH_BA_NGUOI_DAI_DIEN: "/danh-ba-nguoi-dai-dien",
  QUAN_LY_PHAN_QUYEN: "/quan-ly-phan-quyen",
  LS_HOAT_DONG: "/ls-hoat-dong",
  DANH_BA_NHAN_VIEN: "/danh-ba-nhan-vien",
  DANH_SACH_CHUC_VU: "/danh-sach-chuc-vu",
  DUYET_DANG_KY: "/duyet-dang-ky",
  DANH_BA_KHACH_HANG: "/danh-ba-khach-hang",
  NHAT_KY_HE_THONG: "/nhat-ky-he-thong",
  YEU_CAU_HO_TRO: "/yeu-cau-ho-tro",
  YEU_CAU_CAP_NHAT: "/yeu-cau-cap-nhat",
  DANH_MUC_HE_THONG: "/danh-muc-he-thong",
  DANH_MUC_HO_SO: "/danh-muc-ho-so",

  KY_TRA_KET_QUA: "/ky-tra-ket-qua",
  KY_TRA_VAN_BANG: "/ky-tra-van-bang",
  HO_SO_TRUC_TUYEN: "/ho-so-truc-tuyen",
  HO_SO_TRUC_TIEP: "/ho-so-truc-tiep",
  HO_SO_TRUC_TUYEN_THU_PHI: "/ho-so-truc-tuyen-thu-phi",
  HO_SO_TRUC_TIEP_THU_PHI: "/ho-so-truc-tiep-thu-phi",
  DANH_SACH_BIEN_LAI: "/danh-sach-bien-lai",
  XEM_DS_HS_IT: "/danh-sach-hs",
  BAO_CAO_THU_PHI: "/bao-cao-thu-phi",
  BAO_CAO_THU_PHI_2: "/bao-cao-thu-phi-2",
  BAO_CAO_DANG_KY: "/bao-cao-dang-ky",
  THONG_KE: "/thong-ke",
  QUAN_LY_QUY: "/quan-ly-quy",
  QUAN_LY_SO_DU: "/quan-ly-so-du",

  //User
  DICH_VU: DICH_VU,
  TO_KHAI_MOI: `/${DICH_VU}/to-khai-moi`,
  HO_SO_CHO_XU_LY: `/${DICH_VU}/ho-so-cho-xu-ly`,
  HO_SO_DANG_XU_LY: `/${DICH_VU}/ho-so-dang-xu-ly`,
  NHOM_THANH_TOAN: `/${DICH_VU}/nhom-thanh-toan`,
  DANH_SACH_VAN_BANG: `/${DICH_VU}/danh-sach-van-bang`,
  QUAN_LY_TAI_KHOAN_HO_TRO: `/${DICH_VU}/ho-tro`,
  LS_HOAT_DONG_USER: `/${DICH_VU}/ls-hoat-dong-user`,
  DANH_BA_NGUOI_DAI_DIEN_DV: `/${DICH_VU}/danh-ba-nguoi-dai-dien`,
  THONG_TIN_TAI_KHOAN: `/${DICH_VU}/thong-tin-ca-nhan`,
  CAU_HINH_CHU_KY: `/${DICH_VU}/cau-hinh-chu-ky`,
  DANH_SACH_YEU_CAU_HO_TRO: `/${DICH_VU}/danh-sach-yeu-cau-ho-tro`,
  HUONG_DAN_SU_DUNG: `/${DICH_VU}/huong-dan-su-dung`,
  QUESTION: "/question",
  // Router
  // PRINT: "/print",
  TO_KHAI_DANG_KY_SANG_CHE: `/${DICH_VU}/to-khai-dang-ky-sang-che`,
  TO_KHAI_DANG_KY_GPHI: `/${DICH_VU}/to-khai-dang-ky-giai-phap-huu-ich`,
  TO_KHAI_DANG_KY_SANG_CHE_NGUON_GOC_VN: `/${DICH_VU}/to-khai-dang-ky-sang-che-nguon-goc-VN`,
  TO_KHAI_DANG_KY_SANG_CHE_CHI_DINH_VN: `/${DICH_VU}/to-khai-dang-ky-sang-che-chi-dinh-VN`,
  TO_KHAI_DANG_KY_SANG_CHE_CHON_VN: `/${DICH_VU}/to-khai-dang-ky-sang-che-chon-VN`,
  TO_KHAI_DANG_KY_KIEU_DANG_CONG_NGHIEP: `/${DICH_VU}/to-khai-dang-ky-kieu-dang-cong-nghiep`,
  TO_KHAI_DANG_KY_NHAN_HIEU: `/${DICH_VU}/to-khai-dang-ky-nhan-hieu`,
  TO_KHAI_CHUYEN_DOI_DANG_KY_QUOC_TE_NHAN_HIEU_THANH_DON_NOP_THEO_THE_THUC_QUOC_GIA: `/${DICH_VU}/to-khai-chuyen-doi-dang-ky-quoc-te-nhan-hieu-thanh-don-nop-theo-the-thuc-quoc-gia`,
  TO_KHAI_DANG_KY_CHI_DAN_DIA_LY: `/${DICH_VU}/to-khai-dang-ky-chi-dan-dia-ly`,
  TO_KHAI_DANG_KY_THIET_KE_BO_TRI_MACH_BAN_DAN: `/${DICH_VU}/to-khai-dang-ky-thiet-ke-bo-tri-mach-ban-dan`,
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong`,
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-nhuong-quyen-shcn`,
  TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN: `/${DICH_VU}/to-khai-dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn`,
  TO_KHAI_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM: `/${DICH_VU}/to-khai-dang-ky-quoc-te-nhan-hieu-co-nguon-goc-viet-nam`,
  TO_KHAI_DANG_KY_THIET_KE_BO_TRI_MACH_TICH_HOP_BAN_DAN: `/${DICH_VU}/to-khai-dang-ky-thiet-ke-bo-tri-mach-tich-hop-ban-dan`,
  TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to_khai_sua_doi_bo_sung_don_dang_ky_so_huu_cong_nghiep`,
  TO_KHAI_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-chung-chi-hanh-nghe-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-chung-chi-hanh-nghe-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO: `/${DICH_VU}/to-khai-sua-doi-van-bang-bao-ho`,
  TO_KHAI_YEU_CAU_CUNG_CAP_BAN_SAO_TAI_LIEU_THONG_TIN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cung-cap--ban-sao-tai-lieu-thong-tin-so-huu-cong-nghiep`,
  TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN: `/${DICH_VU}/to-khai-cham-dut-hieu-luc-van-bang-bao-ho-shcn`,
  TO_KHAI_HUY_BO_HIEU_LUC_VAN_BANG_BAO_HO_SHCN: `/${DICH_VU}/to-khai-huy-bo-hieu-luc-van-bang-bao-ho-shcn`,
  TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO: `/${DICH_VU}/to-khai-duy-tri-hieu-luc-van-bang-bao-ho`,
  TO_KHAI_GIA_HAN_HIEU_LUC_VAN_BANG_BAO_HO: `/${DICH_VU}/to-khai-gia-han-hieu-luc-van-bang-bao-ho`,
  // TO_KHAI_GIA_HAN_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO_DOI_TUONG_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-gia-han-duy-tri-hieu-luc-van-bang-bao-ho-doi-tuong-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_BAT_BUOC_CHUYEN_GIAO_QUYEN_SU_DUNG_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-bat-buoc-chuyen-giao-quyen-su-dung-sang-che`,
  TO_KHAI_YEU_CAU_CHAM_DUT_QUYEN_SU_DUNG_SANG_CHE_THEO_QUYET_DINH_BAT_BUOC: `/${DICH_VU}/to-khai-yeu-cau-cham-dut-quyen-su-dung-sang-che-theo-quyet-dinh-bat-buoc`,
  TO_KHAI_YEU_CAU_GHI_NHAN_CHUYEN_NHUONG_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhanchuyen-nhuong-don-dang-ky-doi-tuong-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-viec-sua-doi-gia-han-cham-dut-hieu-luc-hop-dong-chuyen-quyen-su-dung-doi-tuong-so-huu-cong-nghiep`,
  TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-thu-tuc-giai-quyet-khieu-nai-lien-quan-den-so-huu-cong-nghiep`,
  TO_KHAI_THU_TUC_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-thu-tuc-dang-ky-du-kiem-tra-nghiep-vu-giam-dinh-so-huu-cong-nghiep`,
  TO_KHAI_KHIEU_NAI: `/${DICH_VU}/to-khai-khieu-nai`,
  TO_KHAI_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-dang-ky-du-kiem-tra-nghiep-vu-dai-dien-so-huu-cong-nghiep`,
  THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN: `/${DICH_VU}/thu-tuc-cap-lai-cap-pho-van-bang-bao-ho-shcn`,
  TO_KHAI_YEU_CAU_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-the-giam-dinh-vien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-the-giam-dinh-vien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_CAP_LAI_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-giay-chung-nhan-to-chuc-du-dieu-kien-hoat-dong-giam-dinh-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_CAP_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-giay-chung-nhan-to-chuc-du-dieu-kien-hoat-dong-giam-dinh-so-huu-cong-nghiep`,
  TO_KHAI_DANG_KY_THAM_DU_KIEM_TRA_NGIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-dng-ky-tham-du-kiem-tra-nghiep-vu-dai-dien-so-huu-cong-nghiep`,
  // TO_KHAI_YEU_CAU_CAP_LAI_CHUNGCHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-chung-chi-hanh-nghe-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to_khai_yeu_cau_ghi_nhan_thay_doi_nguoi_nop_don_dang_ky_doi_tuong_so_huu_cong_nghiep`,
  // TO_KHAI_THU_TUC_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-thu-tuc-cap-the-giam-dinh-vien-so-huu-cong-nghiep`,
  TO_KHAI_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-cap-lai-the-giam-dinh-vien-so-huu-cong-nghiep`,
  TO_KHAI_THU_TUC_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-thu-tuc-cap-the-giam-dinh-vien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-nguoi-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-to-chuc-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_XOA_TEN_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-xoa-ten-to-chuc-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_XOA_TEN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-xoa-ten-nguoi-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DU_DIEU_KIEN_KINH_DOANH_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-to-chuc-du-dieu-kien-kinh-doanh-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_THONG_TIN_CUA_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-ghi-nhan-thay-doi-thong-tin-cua-to-chuc-dich-vu-dai-dien-so-huu-cong-nghiep`,
  TO_KHAI_YEU_CAU_DEN_BU_DO_CHAM_CAP_PHEP_LUU_HANH_LAN_DAU_DOI_VOI_DUOC_PHAM_SAN_XUAT_THEO_BANG_DOC_QUYEN_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-den-bu-do-cham-cap-phep-luu-hanh-lan-dau-doi-voi-duoc-pham-san-xuat-theo-bang-doc-quyen-sang-che`,
  TO_KHAI_DE_NGHI_GIAO_QUYEN_DKSC_KDCN_TKBTLA_KET_QUA_CUA_NHIEM_VU_KHOA_HOC_VA_CONG_NGHE_SU_DUNG_NGAN_SACH_NHA_NUOC: `/${DICH_VU}/to-khai-de-nghi-giao-quyen-dang-ky-sang-che-kieu-dang-cong-nghiep-thiet-ke-bo-tri-la-ket-qua-cua-nhiem-vu-khoa-hoc-va-cong-nghe-su-dung-ngan-sach-nha-nuoc`,
  TO_KHAI_THU_TUC_CAP_LAI_GIAY_CHUNG_NHAN_TO_CHUC_GIAM_DINH_SO_HUU_CONG_NGHIEP: `/${DICH_VU}/to-khai-yeu-cau-cap-lai-giay-chung-nhan-to-chuc-giam-dinh-so-huu-cong-nghiep`,
  TO_KHAI_CHO_PHEP_TO_CHUC_CA_NHAN_KHAC_SU_DUNG_SANG_CHE_KIEU_DANG_CONG_NGHIEP_THIET_KE_BO_TRI_LA_KET_QUA_CUA_NHIEM_VU_KHOA_HOC_VA_CONG_NGHE_SU_DUNG_NGAN_SACH_NHA_NUOC: `/${DICH_VU}/to-khai-cho-phep-to-chuc-ca-nhan-khac-su-dung-sang-che-kieu-dang-cong-nghiep-thiet-ke-bo-tri-la-ket-qua-cua-nhiem-vu-khoa-hoc-va-cong-nghe-su-dung-ngan-sach-nha-nuoc`,
  TO_KHAI_YEU_CAU_THAM_DINH_NOI_DUNG_DON_DANG_KY_SANG_CHE: `/${DICH_VU}/to-khai-yeu-cau-tham-dinh-noi-dung-don-dang-ky-sang-che`,

  //HUONG DAN THU TUC
  HUONG_DAN_DANG_KY_SANG_CHE: "/thu-tuc-hanh-chinh/dang-ky-sang-che",
  HUONG_DAN_DANG_KY_SANG_CHE_NGUON_GOC_VN:
    "/thu-tuc-hanh-chinh/dang-ky-sang-che-nguon-goc-vn",
  HUONG_DAN_DANG_KY_SANG_CHE_CHI_DINH_VN:
    "/thu-tuc-hanh-chinh/dang-ky-sang-che-chi-dinh-VN",
  HUONG_DAN_DANG_KY_SANG_CHE_CHON_VN:
    "/thu-tuc-hanh-chinh/dang-ky-sang-che-co-chon-VN",
  HUONG_DAN_DANG_KY_KIEU_DANG_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/dang-ky-kieu-dang-cong-nghiep",
  HUONG_DAN_DANG_KY_NHAN_HIEU: "/thu-tuc-hanh-chinh/dang-ky-nhan-hieu",
  HUONG_DAN_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM:
    "/thu-tuc-hanh-chinh/dang-ky-nhan-hieu-co-nguon-goc-VN",
  HUONG_DAN_DANG_KY_CHI_DAN_DIA_LY:
    "/thu-tuc-hanh-chinh/dang-ky-chi-dan-dia-ly",
  HUONG_DAN_DANG_KY_THIET_KE_BO_TRI_MACH_BAN_DAN:
    "/thu-tuc-hanh-chinh/dang-ky-thiet-ke-bo-tri-mach-ban-dan",

  HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_NHUONG:
    "/thu-tuc-hanh-chinh/dang-ky-hop-dong-chuyen-nhuong",
  HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN:
    "/thu-tuc-hanh-chinh/dang-ky-hop-dong-chuyen-nhuong-quyen-shcn",
  HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN:
    "/thu-tuc-hanh-chinh/dang-ky-hop-dong-chuyen-quyen-su-dung-doi-tuong-shcn",
  HUONG_DAN_SUA_DOI_BO_SUNG_TACH_DON_DANG_KY_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/sua-doi-bo-sung-tach-don-dang-ky-so-huu-cong-nghiep",
  HUONG_DAN_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/yeu-cau-cap-chung-chi-hanh-nghe-dich-vu-dai-dien-shcn",
  HUONG_DAN_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/yeu-cau-cap-lai-chung-chi-hanh-nghe-dich-vu-dai-dien-shcn",
  HUONG_DAN_SUA_DOI_VAN_BANG_BAO_HO:
    "/thu-tuc-hanh-chinh/sua-doi-van-bang-bao-ho",
  HUONG_DAN_YEU_CAU_CUNG_CAP_BAN_SAO_TAI_LIEU_THONG_TIN_SO_HUU_CONG_NGHIEP:
    "/thu-tuc-hanh-chinh/yeu-cau-cung-cap-ban-sao-tai-lieu-thong-tin-shcn",
  HUONG_DAN_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN:
    "/thu-tuc-hanh-chinh/chan-dut-hieu-luc-van-bang-bao-ho-shcn",
  HUONG_DAN_HUY_BO_HIEU_LUC_VAN_BANG_BAO_HO_SHCN:
    "/thu-tuc-hanh-chinh/huy-bo-hieu-luc-van-bang-bao-ho-SHCN",

  HUONG_DAN_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO:
    "/thu-tuc-hanh-chinh/duy-tri-gia-han-hieu-luc-van-bang-bao-ho",
  HUONG_DAN_GIA_HAN_HIEU_LUC_VAN_BANG_BAO_HO:
    "/thu-tuc-hanh-chinh/duy-tri-gia-han-hieu-luc-van-bang-bao-ho",
  // HUONG_DAN_YEU_CAU_BAT_BUOC_CHUYEN_GIAO_QUYEN_SU_DUNG_SANG_CHE:"",
  // HUONG_DAN_YEU_CAU_CHAM_DUT_QUYEN_SU_DUNG_SANG_CHE_THEO_QUYET_DINH_BAT_BUOC:"",
  // HUONG_DAN_YEU_CAU_GHI_NHAN_CHUYEN_NHUONG_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_KHIEU_NAI:"",
  // HUONG_DAN_THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN:"",
  // HUONG_DAN_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_GIAM_DINH_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_CAP_LAI_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_CAP_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_DANG_KY_THAM_DU_KIEM_TRA_NGIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_GHI_NHAN_SUA_DOI_THONG_TIN_VE_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP:"",
  // HUONG_DAN_YEU_CAU_GHI_NHAN_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP:"",
}
export default ROUTER
